// @ts-nocheck
import React, { useState } from "react";
import FuzzySearchBar from "./LabelsFuzzySearchBar";
import LabelsTable from "./LabelsTable";

interface Props {
  validSearchToken: string | null;
}

export enum SearchQueryType {
  LABEL_ID = "LABEL_ID",
  KEYWORD = "KEYWORD",
}

export interface ISearchQuery {
  type: SearchQueryType;
  labelId?: string;
  keyword?: string;
}

export const LabelsContainer: React.FunctionComponent<Props> = ({
  validSearchToken,
}: Props) => {
  const [searchQuery, setSearchQuery] = useState<ISearchQuery>(
    {} as ISearchQuery,
  );

  return (
    <>
      {validSearchToken && <FuzzySearchBar setSearchQuery={setSearchQuery} />}
      <LabelsTable searchQuery={searchQuery} />
    </>
  );
};
