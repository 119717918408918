import { Box, Typography } from "@helo/ui";

interface IToolTipProps {
  zoneName: string;
  outsideOfCoverage?: string;
}

const ToolTip = ({ zoneName, outsideOfCoverage }: IToolTipProps) => {
  const stringArr = zoneName.split(" - ");
  const title = stringArr[0];
  const subtitle = stringArr[1];
  return (
    <Box
      sx={{
        width: "fit-content",
        display: "flex",
        flexDirection: "column",
        "& .zone-tooltip__body-text": {
          fontFamily: "Sofia Pro",
          fontSize: 16,
          color: "#ffffff",
        },
        "& .zone-tooltip__title-text": {
          fontSize: 20,
          fontWeight: "900",
        },
      }}
    >
      {!outsideOfCoverage ? (
        <>
          <Typography className="zone-tooltip__body-text zone-tooltip__title-text">
            {title}
          </Typography>
          <Typography className="zone-tooltip__body-text">
            {subtitle}
          </Typography>
        </>
      ) : (
        <Typography className="zone-tooltip__body-text">
          {outsideOfCoverage}
        </Typography>
      )}
    </Box>
  );
};

export default ToolTip;
