// TODO: move all the following into translation keys instead,

export enum UserRoles {
  ECOMMERCE = "I am an eCommerce Brand",
  RETAILER = "I am a Retailer",
  "3PL" = "I am a 3PL",
  COURIER = "I am a Carrier/Courier",
}

export type UserRolesKey = keyof typeof UserRoles;

export enum Country {
  CA = "Canada",
  US = "United States",
}

export enum MonthlyVolume {
  VERY_SMALL = "Up to 1,000 per month",
  SMALL = "1,000-5,000 per month",
  MEDIUM = "5,000-25,000 per month",
  LARGE = "25,000-100,000 per month",
}

export enum PackageSize {
  BAG = "Poly bags",
  SMALL = "Small box",
  MEDIUM = "Medium box",
  LARGE = "Large box",
  EXTRA_LARGE = "Extra large box",
}

export enum PackageWeight {
  VERY_LIGHT = "<2 lbs",
  LIGHT = "2 - 5 lbs",
  MEDIUM = "5 - 10 lbs",
  HEAVY = "10 - 15 lbs",
  V1_HEAVY = "15 - 20 lbs",
  V2_HEAVY = "20 - 25 lbs",
  V3_HEAVY = "25 - 30 lbs",
  V4_HEAVY = "30+ lbs",
}

export enum FleetSize {
  SMALL = "1-10",
  MEDIUM = "11-50",
  LARGE = "51-100",
  EXTRA_LARGE = "100+",
}

export enum DSPSoftware {
  XCELERATOR = "Xcelerator",
  CXT = "CXT",
  ECOURIER = "Ecourier",
  OTHER = "Other",
}
