import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";

interface LogoProps {
  variant?: "light" | "primary";
}

// eslint-disable-next-line import/prefer-default-export
export const Logo = styled((props: LogoProps) => {
  const { variant, ...other } = props;

  const color = variant === "light" ? "#ffffff" : "#031A6B";

  return (
    <svg
      width="562"
      height="198"
      viewBox="0 0 562 198"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...other}
    >
      <path
        d="M180.436 35.4942L146.586 193.224H127.157C124.427 193.323 121.706 192.876 119.155 191.91C116.605 190.944 114.277 189.478 112.311 187.601C110.346 185.723 108.783 183.473 107.716 180.984C106.649 178.495 106.1 175.818 106.102 173.114C106.117 170.86 106.367 168.613 106.847 166.41L112.94 138.055H52.7555L40.9082 193.224H21.2075C18.4737 193.395 15.7339 192.996 13.1658 192.052C10.5976 191.108 8.2584 189.641 6.29921 187.745C4.34002 185.85 2.80491 183.568 1.79283 181.047C0.780759 178.527 0.314176 175.824 0.423897 173.114C0.456085 170.861 0.70576 168.616 1.1689 166.41L33.7994 13.6413H57.2234C59.5406 13.4926 61.8639 13.8212 64.0462 14.6068C66.2285 15.3925 68.2224 16.6177 69.9021 18.2052C71.5818 19.7928 72.9104 21.7081 73.8044 23.8301C74.6984 25.9521 75.1384 28.2346 75.096 30.5334C75.046 32.1983 74.8648 33.8572 74.5543 35.4942L60.4055 101.053H121.335L139.952 13.9093H162.021C164.433 13.7735 166.848 14.1214 169.12 14.9321C171.393 15.7428 173.477 16.9999 175.247 18.6275C177.017 20.255 178.437 22.2195 179.422 24.4037C180.406 26.5878 180.936 28.9463 180.977 31.3378C180.911 32.7354 180.73 34.1258 180.436 35.4942Z"
        fill={color}
      />
      <path
        d="M247.459 138.063H225.66V139.068C225.66 158.374 244.074 162.798 259.51 162.798C275.2 163.691 290.815 160.059 304.462 152.341L302.904 162.262C299.993 179.691 290.245 197.12 242.653 197.12C214.558 197.12 181.724 179.691 181.724 136.253C181.724 104.479 204.064 46.1602 266.076 46.1602C282.121 46.1602 323.688 56.5503 323.688 93.0835C323.214 122.712 306.357 138.063 247.459 138.063ZM260.999 80.2803C242.856 80.2803 233.919 89.5977 228.165 108.903H250.777C273.93 108.903 282.054 106.825 282.054 94.0221C281.918 86.8495 273.794 80.1461 260.864 80.1461L260.999 80.2803Z"
        fill={color}
      />
      <path
        d="M399.921 173.983C396.807 190.138 380.492 195.568 365.26 195.568C343.934 195.568 327.687 184.642 327.687 160.711C327.713 156.066 328.235 151.438 329.244 146.902L360.521 0.835938H376.024C392.069 0.835938 400.193 6.60081 400.193 20.946C400.151 25.2575 399.628 29.5515 398.635 33.7496L376.024 139.394C375.585 141.283 375.403 143.222 375.482 145.159C375.482 154.745 382.252 157.895 394.167 157.895H403.104L399.921 173.983Z"
        fill={color}
      />
      <path
        d="M473.85 197.114C465.622 197.444 457.413 196.106 449.726 193.182C442.039 190.259 435.036 185.811 429.147 180.112C423.258 174.412 418.607 167.582 415.479 160.039C412.351 152.496 410.813 144.4 410.958 136.248C410.958 86.509 442.776 46.1549 498.966 46.1549C507.212 46.0561 515.394 47.5904 523.033 50.6673C530.671 53.7442 537.611 58.3019 543.445 64.0723C549.279 69.8428 553.889 76.7093 557.005 84.2691C560.12 91.8289 561.679 99.9291 561.588 108.094C561.656 147.912 536.404 197.114 473.85 197.114ZM492.467 82.8224C468.57 82.8224 456.993 108.094 456.993 135.711C456.993 158.637 473.85 160.983 480.417 160.983C504.044 160.983 515.621 136.449 515.621 109.166C515.621 85.1685 499.305 82.8224 492.467 82.8224Z"
        fill={color}
      />
    </svg>
  );
})``;

Logo.defaultProps = {
  variant: "primary",
};

Logo.propTypes = {
  variant: PropTypes.oneOf(["light", "primary"]),
};
