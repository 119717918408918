import { SvgIcon } from "@mui/material";

const CreateLabelIcon = () => {
  return (
    <SvgIcon>
      <path d="M17 19.22H5V7h7V5H5c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2v-7h-2v7.22Z" fill="#000" fillOpacity=".54"/>
       <path d="M19 2h-2v3h-3c.01.01 0 2 0 2h3v2.99c.01.01 2 0 2 0V7h3V5h-3V2ZM15 9H7v2h8V9ZM7 12v2h8v-2H7ZM15 15H7v2h8v-2Z" fill="#000" fillOpacity=".54"/>
    </SvgIcon>
  );
}

export default CreateLabelIcon;