import { Box, InboxIcon, Typography } from "@helo/ui";
import { useResourceContext, useTranslate } from "react-admin";

/**
 * Component to be used as a replacement for the empty state in a react-admin list view.
 *
 * Must be used under a react-admin resource list route.
 */
const ResourceEmptyState: React.FunctionComponent = ({ children }) => {
  const translate = useTranslate();
  const resource = useResourceContext();

  return (
    <Box
      sx={(theme) => ({
        display: "grid",
        width: "100%",
        height: "100%",
        placeContent: "center",
        placeItems: "center",
        color: theme.palette.neutral.light,
      })}
    >
      <InboxIcon sx={{ width: "200px", height: "200px" }} />
      <Typography variant="h3">
        {translate("ra.page.empty", {
          name: translate(`resources.${resource}.name`, { smart_count: 2 }),
        })}
      </Typography>
      {children}
    </Box>
  );
};

export default ResourceEmptyState;
