import { Loading as LoadingRa, LoadingProps } from "react-admin";

const Loading = (props: LoadingProps) => {
  /* NOTE: react-admin's Loading component has a weird bug where past a certain viewport, the component doesn't stretch to 100vh and margin isn't applied */
  return (
    <LoadingRa
      {...props}
      sx={(theme: any) => ({
        [theme.breakpoints.up("xl")]: { height: "100vh", mt: "-3em" },
      })}
    />
  );
};

export default Loading;
