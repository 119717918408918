// @ts-nocheck
import { AutoComplete, Col, Input, Row, Space, Typography } from "antd";
import { withSearch } from "@elastic/react-search-ui";
import { LabelStateTag } from "~/common/labelHelpers";
import { SEARCH_CONFIGURATION } from "~/config/elasticAppSearch";
import { TrackingEventState } from "@swyft/swyft-common";
import { SearchOutlined } from "@ant-design/icons";
import { ISearchQuery, SearchQueryType } from "./LabelsContainer";
import moment from "moment-timezone";
import { styled, Box } from "@helo/ui";

const { Text } = Typography;
const { Search } = Input;

interface PassedProps {
  setSearchQuery: (query: ISearchQuery) => void;
}

interface ElasticProps {
  isLoading: boolean;
  searchTerm: string;
  setSearchTerm: any;
  autocompletedResults: IResult[];
  [propName: string]: any;
}

type Props = ElasticProps & PassedProps;

interface IResultField {
  snippet?: string | null;
  raw?: string | null;
}

interface IResult {
  created_at: IResultField;
  first_name: IResultField;
  last_name: IResultField;
  email: IResultField;
  phone: IResultField;
  line1: IResultField;
  line2: IResultField;
  city: IResultField;
  province: IResultField;
  postal_code: IResultField;
  shopify_order_id?: IResultField;
  shopify_order_number?: IResultField;
  tracking_number?: IResultField;
  order_number?: IResultField;
  state: { raw: TrackingEventState };
  id: { raw: string };
}

const StyledAutocomplete = styled(AutoComplete)({});

const getSnippet = (field?: IResultField) => {
  return field?.snippet || "";
};

const renderField = (field?: IResultField) => {
  return (
    <Box
      component="span"
      sx={{
        whiteSpace: "initial",
        overflowWrap: "break-word",
        "& em": {
          fontStyle: "normal",
          color: "#fff",
          backgroundColor: "#2196f3",
          borderRadius: 2,
          padding: "1px 5px"
        }
      }}
      dangerouslySetInnerHTML={{
        __html: getSnippet(field),
      }}
    />
  );
};

const renderIdField = (label: string, field?: IResultField) => {
  return (
    field &&
    field.raw &&
    field.raw !== field.snippet && (
      <Text type="secondary">
        <Box
          component="span"
          sx={{
            whiteSpace: "initial",
            overflowWrap: "break-word",
            "& em": {
              fontStyle: "normal",
              color: "#fff",
              backgroundColor: "#2196f3",
              borderRadius: 2,
              padding: "1px 5px"
            }
          }}
          dangerouslySetInnerHTML={{
            __html: `${label}: ${getSnippet(field)}`,
          }}
        />
      </Text>
    )
  );
};

const renderAutocompletedResult = (result: IResult) => {
  return (
    <>
      <Row gutter={24}>
        <Col span={4}>
          {moment(result.created_at.raw).format("MMM. D, h:mm a")}
        </Col>
        <Col span={3}>
          {renderField(result.first_name)} {renderField(result.last_name)}
        </Col>
        <Col span={4}>{renderField(result.phone)}</Col>
        <Col span={7}>
          {renderField(result.line1)}, {renderField(result.city)},{" "}
          {renderField(result.province)},{renderField(result.postal_code)}
        </Col>
        <Col span={3}>{renderField(result.order_number)}</Col>
        <Col span={3}>
          <LabelStateTag state={result.state.raw} />
        </Col>
      </Row>
      <Row gutter={24} style={{ paddingTop: "10px", paddingLeft: "13px" }}>
        <Space direction="horizontal">
          {renderIdField("Email", result.email)}
          {renderIdField("Shopify Order Id", result.shopify_order_id)}
          {renderIdField("Shopify Order Number", result.shopify_order_number)}
          {renderIdField("Tracking Number", result.tracking_number)}
        </Space>
      </Row>
    </>
  );
};

const FuzzySearchBar: React.FunctionComponent<Props> = ({
  isLoading,
  searchTerm,
  setSearchTerm,
  autocompletedResults,
  setSearchQuery
}: Props) => {
  /**
   * Calls ES to fetch new results, these get passed down as autocompletedResults
   */
  const handleOnSearchTermChange = (newSearchString: string) => {
    setSearchTerm(newSearchString, {
      autocompleteResults: true,
      ...SEARCH_CONFIGURATION,
    });
  };

  const renderOptions = () => {
    const dropdownOptions: any = [];

    if (autocompletedResults.length > 0) {
      // Add the column header to the dropdown results
      dropdownOptions.push({
        value: "",
        key: "header",
        label: (
          <Row gutter={24}>
            <Col span={4}>
              <Typography.Text style={{ color: "#8c8c8c" }}>
                Created At
              </Typography.Text>
            </Col>
            <Col span={3}>
              <Typography.Text style={{ color: "#8c8c8c" }}>
                Name
              </Typography.Text>
            </Col>
            <Col span={4}>
              <Typography.Text style={{ color: "#8c8c8c" }}>
                Phone
              </Typography.Text>
            </Col>
            <Col span={7}>
              <Typography.Text style={{ color: "#8c8c8c" }}>
                Address
              </Typography.Text>
            </Col>
            <Col span={3}>
              <Typography.Text style={{ color: "#8c8c8c" }}>
                Order #
              </Typography.Text>
            </Col>
            <Col span={3}>
              <Typography.Text style={{ color: "#8c8c8c" }}>
                State
              </Typography.Text>
            </Col>
          </Row>
        ),
      });

      // Populate the results
      autocompletedResults.forEach((result) => {
        dropdownOptions.push({
          key: result.id.raw,
          value: result.id.raw,
          label: renderAutocompletedResult(result),
        });
      });
    }

    // Last dropdown option
    dropdownOptions.push({
      value: "",
      key: "more",
      label: (
        <Row justify="space-between" style={{ marginTop: "5px" }}>
          <Space>
            <Text type="secondary">
              <SearchOutlined />
            </Text>
            {autocompletedResults.length > 0 ? (
              <Text type="secondary">
                More search results for "{searchTerm}"
              </Text>
            ) : (
              <Text type="secondary">No matching labels were found.</Text>
            )}
          </Space>
          {autocompletedResults.length > 0 && (
            <Text type="secondary">Press ENTER</Text>
          )}
        </Row>
      ),
    });
    return dropdownOptions;
  };

  const handleSetSearchQuery = (query: ISearchQuery) => {
    setSearchQuery(query);
  };

  return (
    <StyledAutocomplete
      sx={{
        width: "100%",
      }}
      value={searchTerm} // The state of searchTerm is handled by the ES provider.
      onSearch={handleOnSearchTermChange}
      options={renderOptions()}
      onSelect={(labelId) => {
        if (!labelId) {
          handleSetSearchQuery({
            type: SearchQueryType.KEYWORD,
            keyword: searchTerm,
          }); // If user clicks load more from the AutoComplete
        } else {
          handleSetSearchQuery({ type: SearchQueryType.LABEL_ID, labelId });
        }
      }}
    >
      <Search
        value={searchTerm}
        enterButton
        allowClear
        size="large"
        loading={isLoading}
        placeholder="Search for a label..."
        onSearch={(newSearchTerm) => {
          if (!newSearchTerm) {
            handleSetSearchQuery(null);
            return;
          }

          handleSetSearchQuery({
            type: SearchQueryType.KEYWORD,
            keyword: newSearchTerm,
          });
        }}
      />
    </StyledAutocomplete>
  );
};

export default withSearch(
  ({ isLoading, searchTerm, setSearchTerm, autocompletedResults }: Props) => ({
    isLoading,
    searchTerm,
    setSearchTerm,
    autocompletedResults
  }),
)(FuzzySearchBar);
