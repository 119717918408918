import { Box, Typography, styled } from "@helo/ui";
import { useTranslate } from "react-admin";

const Footer = () => {
  const translate = useTranslate();
  const year = new Date().getFullYear();

  return (
    <FooterContainer>
      <Typography
        sx={({ palette }) => ({
          color: palette.primary.contrastText,
          fontWeight: 600,
        })}
        variant="subtitle1"
      >
        {" "}
        &copy;
        {translate("footer.copyright", { year: year })}
      </Typography>
    </FooterContainer>
  );
};

export default Footer;

const FooterContainer = styled(Box)(({ theme }) => ({
  padding: "16px",
  marginTop: "auto",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  backgroundImage: "linear-gradient(to right,#031A6B,#1a237e)",
  boxShadow: "0px -5px 10px 0px rgba(0, 0, 0, 0.1)",
  zIndex: "1",
  [theme.breakpoints.up("sm")]: {
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
  },
}));
