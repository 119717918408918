import {
  useState,
  useEffect,
  ReactNode,
  Dispatch,
  SetStateAction,
} from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@helo/ui";
import { useTranslate } from "react-admin";

export default function NotifyDialog({
  title,
  content,
  dismissLabel = "shared.action.ok",
  isOpen = false,
  renderActions,
  onClose,
}: NotifyDialogProps) {
  const [open, setOpen] = useState(isOpen);
  const t = useTranslate();

  const handleClose = () => {
    if (onClose) {
      onClose();
      return;
    }

    setOpen(false);
  };

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  const titleHtmlId = `${ROOT_PREFIX}-title`;
  const contentHtmlId = `${ROOT_PREFIX}-desc`;

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby={titleHtmlId}
      aria-describedby={contentHtmlId}
    >
      <DialogTitle id={titleHtmlId} sx={{ fontWeight: "900" }}>
        {typeof title === "string" ? t(title) : title}
      </DialogTitle>
      <DialogContent sx={{ py: 0 }}>
        <DialogContentText
          id={contentHtmlId}
          sx={({ palette }) => ({
            color: palette.neutral.contrastText,
          })}
        >
          {typeof content === "string" ? t(content) : content}
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ px: 3, py: 2 }}>
        {renderActions ? (
          renderActions(setOpen)
        ) : (
          <Button
            onClick={handleClose}
            variant="contained"
            color="primary"
            autoFocus
          >
            {t(dismissLabel)}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

const ROOT_PREFIX = "notify-dialog";

export type NotifyDialogProps = {
  title?: ReactNode;
  content?: ReactNode;
  dismissLabel?: string;
  isOpen?: boolean;
  renderActions?: (setOpen: Dispatch<SetStateAction<boolean>>) => ReactNode;
  onClose?: () => void;
};
