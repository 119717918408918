import posthog from "posthog-js";

const POSTHOG_API_KEY = process.env.REACT_APP_POSTHOG_API_KEY ?? "";

export const initializeAnalytics = () => posthog.init(POSTHOG_API_KEY);

/**
 * Identify a user for analytics
 *
 * @param id Unique identifier for user
 * @returns
 */
export const setUserOnLogin = (user: {
  id: string;
  email?: string;
  name?: string;
}) => posthog.identify(user.id, { email: user.email, name: user.name });

/**
 * Reset a user for analytics
 */
export const resetUserOnLogout = () => posthog.reset();
