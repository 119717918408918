import { Translate } from "react-admin";
import { object, string } from "yup";
import { MonthlyVolume, PackageSize, PackageWeight } from "~/constants/form";
import { CountryISO2 } from "@swyft/swyft-common";

export const getMerchantSchema = (translate: Translate) =>
  object({
    country: string()
      .oneOf(
        Object.values(CountryISO2),
        translate("shared.validation.required_select"),
      )
      .nullable(),
    companyName: string().min(1, translate("shared.validation.required")),
    jobTitle: string().min(1, translate("shared.validation.required")),
    monthlyVolume: string()
      .oneOf(
        Object.keys(MonthlyVolume),
        translate("shared.validation.required_select"),
      )
      .nullable(),
    packageSize: string()
      .oneOf(
        Object.keys(PackageSize),
        translate("shared.validation.required_select"),
      )
      .nullable(),
    packageWeight: string()
      .oneOf(
        Object.keys(PackageWeight),
        translate("shared.validation.required_select"),
      )
      .nullable(),
  });
