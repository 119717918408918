import { useState, forwardRef } from "react";
import {
  IconButton,
  InputAdornment,
  TextField,
  VisibilityOffIcon,
  VisibilityIcon,
  StandardTextFieldProps,
} from "../../index";

interface PasswordFieldProps extends StandardTextFieldProps {}

const PasswordField = (
  { error = false, ...others }: PasswordFieldProps,
  ref: any,
) => {
  const [visible, setVisible] = useState<boolean>(false);

  const togglePasswordVisibility = () => setVisible(!visible);

  return (
    <TextField
      type={visible ? "text" : "password"}
      InputProps={{
        ref,
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={togglePasswordVisibility}
              onMouseDown={(e) => e.preventDefault()}
              edge="end"
              sx={{ color: error ? "error" : "gray" }}
              color={error ? "error" : "default"}
            >
              {visible ? <VisibilityOffIcon /> : <VisibilityIcon />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      error={error}
      {...others}
    />
  );
};

export default forwardRef(PasswordField);
