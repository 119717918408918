import {
  Avatar,
  Box,
  ButtonBase,
  ListItemText,
  ListItemIcon,
  PowerSettingsNewOutlined,
  Menu,
  MenuItem,
  Typography,
} from "@helo/ui";
import { MouseEvent, useState } from "react";
import { useLogout, useTranslate, useNotify } from "react-admin";
import { useGetIdentity } from "~/services/auth/hooks";

const AccountButton = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { identity, isLoading: isIdentityLoading } = useGetIdentity();
  const open = Boolean(anchorEl);
  const logout = useLogout();
  const translate = useTranslate();
  const notify = useNotify();
  const name = identity?.fullName;
  const email = identity?.email;

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  /**
   * Handle Logout using firebase and redirect to login page
   */
  const handleLogout = async () => {
    try {
      await logout();
    } catch (err: any) {
      notify(
        `${translate("user.message.logout.fail")} ${
          err?.message ?? JSON.stringify(err)
        }`,
        { type: "warning" },
      );
    }
  };

  if (isIdentityLoading || !identity) {
    return null;
  }

  return (
    <>
      <Box
        sx={{
          marginLeft: 1,
          "& .MuiButton-root": {
            whiteSpace: "nowrap",
          },
        }}
      >
        <ButtonBase
          onClick={handleClick}
          aria-controls={open ? "account-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          color="primary"
        >
          <Avatar></Avatar>
          <Typography
            variant="subtitle1"
            color={({ palette }) => palette.neutral.dark}
            sx={{ ml: 1 }}
          >
            {name}
          </Typography>
        </ButtonBase>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem disabled>
          <ListItemText>{email}</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <PowerSettingsNewOutlined fontSize="small" />
          </ListItemIcon>
          {translate("ra.auth.logout")}
        </MenuItem>
      </Menu>
    </>
  );
};

export default AccountButton;
