import { Container, Typography, Button, Box, styled } from "@helo/ui";
import { useTranslate } from "react-admin";
import { useNavigate } from "react-router-dom";

import { Routes } from "~/config/Routes";
import { ReactComponent as PageNotFoundImg } from "~/assets/images/page_not_found.svg";
import { ReactComponent as GenericErrorImg } from "~/assets/images/generic_error.svg";

const CustomError = ({ error }: CustomErrorProps) => {
  const translate = useTranslate();
  const navigate = useNavigate();
  const goToDash = () => {
    navigate(Routes.Dashboard);
  };

  const ErrorContainer = styled(Container)({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    marginTop: 64,
  });

  return (
    <ErrorContainer>
      <Typography
        variant="h2"
        align="center"
        fontWeight={900}
        textTransform="capitalize"
      >
        {translate(error.title)}
      </Typography>
      <Typography variant="body1" align="center" sx={{ maxWidth: "90%" }}>
        {translate(error.desc)}
      </Typography>
      <Button onClick={goToDash} variant="contained" size="large" sx={{ m: 2 }}>
        {translate("shared.action.return_to", {
          name: translate("pages.dashboard.title"),
        })}
      </Button>
      <Box sx={{ m: 4, maxWidth: "90%" }}>
        {(error.type === ErrorType.Generic && <GenericErrorImg />) ||
          (error.type === ErrorType.PageNotFound && <PageNotFoundImg />)}
      </Box>
    </ErrorContainer>
  );
};

export enum ErrorType {
  Generic,
  PageNotFound,
}

interface ErrorDetails {
  type: ErrorType;
  title: string;
  desc: string;
}

interface CustomErrorProps {
  error: ErrorDetails;
}

export default CustomError;
