import * as Sentry from "@sentry/react";
import { BrowserRouter } from "react-router-dom";
import { DomainContextProvider } from "./components/DomainContext";

import UnhandledError from "./components/feedback/UnhandledError";
import MainAdmin from "./MainAdmin";

const App = () => {
  return (
    <Sentry.ErrorBoundary fallback={UnhandledError}>
      <BrowserRouter>
        <DomainContextProvider>
          <MainAdmin />
        </DomainContextProvider>
      </BrowserRouter>
    </Sentry.ErrorBoundary>
  );
};

export default App;
