import { forwardRef } from "react";
import { Logout, UserMenu, UserMenuProps } from "react-admin";
import { MenuItem, ListItemText, ThemeType, useMediaQuery } from "@helo/ui";

import { useAuthenticatedContext } from "~/components/AuthenticatedContext";

const CurrentUserEmail = forwardRef((props: any, ref) => {
  const { identity } = useAuthenticatedContext();
  const isMobileLarge = useMediaQuery((theme: ThemeType) =>
    theme.breakpoints.down("md"),
  );

  return (
    <MenuItem ref={ref} disabled {...props} dense={isMobileLarge}>
      <ListItemText sx={{ whiteSpace: "pre-line" }}>
        {identity?.email}
      </ListItemText>
    </MenuItem>
  );
});

const AppCurrentUserMenu = (props: UserMenuProps) => (
  <UserMenu {...props}>
    <CurrentUserEmail />
    <Logout />
  </UserMenu>
);

export default AppCurrentUserMenu;
