import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import reportWebVitals from "./reportWebVitals";
import { initializeAnalytics } from "./services/analytics";
import { initializeRemoteConfig } from "./services/remote-config";
import App from "./App";
import "./index.css";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      ),
    }),
  ],
  tracesSampleRate: 1.0,

  // enabled: process.env.VERCEL_ENV === "production",
  environment: process.env.VERCEL_ENV,
  release: process.env.VERCEL_GIT_COMMIT_SHA,
});

initializeAnalytics();

const AppWithRemoteConfig = () => {
  const [isFetching, setIsFetching] = useState(true);

  useEffect(() => {
    initializeRemoteConfig()
      .then(() => {})
      .catch((error) => Sentry.captureException(error))
      .finally(() => setIsFetching(false));
  }, [initializeRemoteConfig]);

  if (isFetching) {
    return null;
  }

  return <App />;
};

ReactDOM.render(
  <React.StrictMode>
    <AppWithRemoteConfig />
  </React.StrictMode>,
  document.getElementById("root"),
);

reportWebVitals();
