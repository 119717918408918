import { initializeApp } from "firebase/app";
import { getAuth, connectAuthEmulator } from "firebase/auth";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import { getStorage, connectStorageEmulator } from "firebase/storage";
import firebaseConfig from "~/config/firebase";

const firebaseApp = initializeApp(firebaseConfig);
const firebaseAuth = getAuth(firebaseApp);
const firebaseDb = getFirestore(firebaseApp);
const firebaseFunctions = getFunctions(firebaseApp);
const firebaseStorage = getStorage(firebaseApp);
const firebasePublicStorage = getStorage(
  firebaseApp,
  process.env.REACT_APP_FIREBASE_PUBLIC_BUCKET,
);

if (process.env.REACT_APP_CONNECT_FIREBASE_EMULATORS === "all") {
  connectAuthEmulator(firebaseAuth, "http://localhost:9099");
  connectFirestoreEmulator(firebaseDb, "localhost", 8080);
  connectStorageEmulator(firebaseStorage, "localhost", 9199);
}

// the functions emulator can be connected in isolation
if (
  process.env.REACT_APP_CONNECT_FIREBASE_EMULATORS === "all" ||
  process.env.REACT_APP_CONNECT_FIREBASE_EMULATORS?.includes("functions")
) {
  connectFunctionsEmulator(firebaseFunctions, "localhost", 5001);
}

export {
  firebaseApp,
  firebaseAuth,
  firebaseDb,
  firebaseFunctions,
  firebaseStorage,
  firebasePublicStorage,
};
