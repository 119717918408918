import polygloti18nProvider from "ra-i18n-polyglot";
import raEnglish from "ra-language-english";
import { raFormLayoutLanguageEnglish } from "@react-admin/ra-form-layout";
import en from "./en.json";

const messages = {
  ...raEnglish,
  ...raFormLayoutLanguageEnglish,
  ...en,
};

const i18nProvider = polygloti18nProvider(() => messages, "en", {
  // temporary silencing
  allowMissing: true,
});

export default i18nProvider;
