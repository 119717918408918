import { Modal, CircularProgress } from "@helo/ui";

import { useGlobalComponentContext } from "~/components/GlobalComponentContext";

const LoadingOverlay = () => {
  const { isLoadingModalOpen } = useGlobalComponentContext();

  return (
    <Modal
      open={isLoadingModalOpen}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress
        sx={({ palette }) => ({
          color: palette.primary.contrastText,
        })}
      />
    </Modal>
  );
};

export default LoadingOverlay;
