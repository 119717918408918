import {
  createContext,
  PropsWithChildren,
  useContext,
  useState,
  Dispatch,
  SetStateAction,
} from "react";
import { NotifyDialogProps } from "~/components/feedback/NotifyDialog";

const GlobalComponentContext = createContext<GlobalComponentContextType | null>(
  null,
);

/**
 * A hook for using the Global Component context.
 * @returns {GlobalComponentContextType} context
 */
export const useGlobalComponentContext = () => {
  const context = useContext(GlobalComponentContext);

  if (!context) {
    throw new Error("Must be called within GlobalAlertsContextProvider.");
  }

  return context;
};

export function GlobalComponentContextProvider({
  children,
}: PropsWithChildren<{}>) {
  const [alertDialogProps, setAlertDialogProps] = useState<NotifyDialogProps>(
    {},
  );
  const [isContactSalesOpen, setIsContactSalesOpen] = useState<boolean>(false);
  const [isLoadingModalOpen, setIsLoadingModalOpen] = useState<boolean>(false);

  return (
    <GlobalComponentContext.Provider
      value={{
        notifyDialog: alertDialogProps,
        setNotifyDialog: setAlertDialogProps,
        isContactSalesOpen,
        setIsContactSalesOpen,
        isLoadingModalOpen,
        setIsLoadingModalOpen,
      }}
    >
      {children}
    </GlobalComponentContext.Provider>
  );
}

interface GlobalComponentContextType {
  notifyDialog: NotifyDialogProps;
  setNotifyDialog: Dispatch<SetStateAction<NotifyDialogProps>>;
  isContactSalesOpen: boolean;
  setIsContactSalesOpen: Dispatch<SetStateAction<boolean>>;
  isLoadingModalOpen: boolean;
  setIsLoadingModalOpen: Dispatch<SetStateAction<boolean>>;
}
