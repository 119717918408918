import { useTranslate } from "react-admin";
import { Box } from "@helo/ui";

import { useGlobalComponentContext } from "~/components/GlobalComponentContext";
import ActionAlert from "./ActionAlert";

const InactiveMerchantAlert = ({ message }: { message: string }) => {
  const translate = useTranslate();
  const { setIsContactSalesOpen } = useGlobalComponentContext();
  return (
    <ActionAlert
      actionHandler={() => {
        setIsContactSalesOpen(true);
      }}
      actionText={translate("shared.action.contact", {
        name: translate("shared.teams.sales"),
      })}
      alertMessage={message}
      sx={{ mb: 3 }}
    />
  );
};

export default InactiveMerchantAlert;
