import { object, string } from "yup";
import { Translate } from "react-admin";

export const getLoginFormSchema = (translate: Translate) =>
  object({
    email: string()
      .email(translate("shared.validation.invalid"))
      .required(translate("shared.validation.required")),
    password: string().required(translate("shared.validation.required")),
  });
