import {
  Box,
  Typography,
  styled,
  SxProps,
  useMediaQuery,
  ThemeType,
} from "@helo/ui";
import { ReactNode } from "react";
import { useResourceContext, useTranslate } from "react-admin";

import GracefulFallback from "~/components/feedback/GracefulFallback";

const AppViewLayout: React.FunctionComponent<AppViewLayoutProps> = ({
  children,
  title,
  subtitle,
  titleFlair,
  banners,
  breadcrumbs,
  context,
  variant,
  sx = [],
}) => {
  const translate = useTranslate();
  const resource = useResourceContext();
  const isMobileLarge = useMediaQuery((theme: ThemeType) =>
    theme.breakpoints.down("md"),
  );

  if (isMobileLarge) {
    return (
      <GracefulFallback
        type="warning"
        title="shared.message.responsive.not_mobile_optimized_header"
        details="shared.message.responsive.not_mobile_optimized"
        rounded={false}
      />
    );
  }

  return (
    <Container
      sx={[
        variant === "centered" && { mx: "auto", boxShadow: "none" },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      {banners}
      <HeaderContainer className={CLASSES.headerContainer}>
        <TitleContainer className={CLASSES.titleContainer}>
          <TitleWrapper>
            <Typography variant="h5" component="h1" fontWeight={900}>
              {!title
                ? translate(`resources.${resource}.name`, { smart_count: 2 })
                : translate(title)}
            </Typography>
            {titleFlair}
          </TitleWrapper>
          <Typography variant="subtitle1" component="h2" fontWeight={600}>
            {subtitle ? translate(subtitle) : null}
          </Typography>
          {breadcrumbs ? (
            <BreadcrumbsContainer>{breadcrumbs}</BreadcrumbsContainer>
          ) : null}
        </TitleContainer>
        <ContextContainer className={CLASSES.contextContainer}>
          {!!context && typeof context === "string" ? (
            <Typography variant="body2" component="h3">
              {translate(context)}
            </Typography>
          ) : (
            context
          )}
        </ContextContainer>
      </HeaderContainer>
      {children}
    </Container>
  );
};

const Container = styled(Box)(({ theme }) => ({
  padding:
    "var(--with-fallback-safe-area-inset-top) var(--with-fallback-safe-area-inset-right) var(--with-fallback-safe-area-inset-bottom) var(--with-fallback-safe-area-inset-left)",
  height: "100%",
  boxShadow: theme.shadows[2],
  [theme.breakpoints.up("sm")]: {
    padding: "24px 44px",
  },
  [theme.breakpoints.up("md")]: {
    padding: "48px 88px",
  },
  [theme.breakpoints.up("xl")]: {
    boxShadow: "none",
  },
}));

const HeaderContainer = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: "24px",
});

const TitleContainer = styled(Box)({
  flexGrow: 1,
});

const TitleWrapper = styled(Box)({
  display: "flex",
});

const BreadcrumbsContainer = styled(Box)({
  marginTop: "8px",
});

const ContextContainer = styled(Box)({});

const CLASS_PREFIX = "app-view-layout";
const CLASSES = {
  headerContainer: `${CLASS_PREFIX}__header-container`,
  titleContainer: `${CLASS_PREFIX}__title-container`,
  contextContainer: `${CLASS_PREFIX}__context-container`,
};

type Variant = "full-width" | "centered";

interface AppViewLayoutProps {
  title?: string;
  subtitle?: string;
  titleFlair?: ReactNode;
  banners?: ReactNode;
  breadcrumbs?: ReactNode;
  context?: ReactNode;
  sx?: SxProps<ThemeType>;
  variant?: Variant;
}

export default AppViewLayout;
