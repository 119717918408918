import { SvgIcon } from "@mui/material";

const ListIcon = () => {
  return (
    <SvgIcon>
      <path d="M3 13h2v-2H3v2Zm0 4h2v-2H3v2Zm0-8h2V7H3v2Zm4 4h14v-2H7v2Zm0 4h14v-2H7v2ZM7 7v2h14V7H7Zm-4 6h2v-2H3v2Zm0 4h2v-2H3v2Zm0-8h2V7H3v2Zm4 4h14v-2H7v2Zm0 4h14v-2H7v2ZM7 7v2h14V7H7Z" fill="#000" fillOpacity=".54"/>
    </SvgIcon>
  );
}

export default ListIcon;