import { Alert, AlertProps, Container, Typography } from "@helo/ui";
import { useState } from "react";
import { useTranslate, useNotify } from "react-admin";
import { useNavigate } from "react-router-dom";
import { useDomainContext } from "~/components/DomainContext";
import { UserIdentity } from "~/services/auth/authProvider";
import { Routes } from "~/config/Routes";

interface ResendEmailVerificationProps {
  user?: UserIdentity;
  onResend?: () => void;
}

const ResendEmailVerification = ({
  onResend,
  user,
  ...rest
}: ResendEmailVerificationProps & AlertProps) => {
  const translate = useTranslate();
  const notify = useNotify();
  const [isSent, setIsSent] = useState<boolean>(false);
  const { usersController } = useDomainContext();
  const navigate = useNavigate();

  const handleResend = async () => {
    notify("shared.message.request.await");

    try {
      await usersController.resendVerificationEmail();
      setIsSent(true);
      onResend?.();
    } catch (err: any) {
      notify(
        `${translate("shared.message.request.fail")}: ${
          err?.message ?? JSON.stringify(err)
        }`,
        { type: "warning" },
      );
    }
  };

  if (isSent) {
    return (
      <Alert severity="success" {...rest}>
        {translate("user.message.verify_email.sent")}
      </Alert>
    );
  }

  return (
    <Container>
      <Alert severity="error" {...rest}>
        <Typography component="span" variant="body2">
          {translate("user.message.verify_email.not_verified")}
        </Typography>
        &nbsp;
        <Typography
          component="a"
          onClick={handleResend}
          variant="body2"
          fontWeight={500}
          color="primary"
          sx={{ cursor: "pointer", display: "inline-block" }}
        >
          {translate("user.message.verify_email.resend")}
        </Typography>
        &nbsp;
        <Typography variant="body2" component="span">
          {translate("user.message.verify_email.or")}
        </Typography>
        &nbsp;
        <Typography
          component="a"
          onClick={() => navigate(Routes.Login)}
          variant="body2"
          fontWeight={500}
          color="primary"
          sx={{ cursor: "pointer", display: "inline-block" }}
        >
          {translate("ra.auth.sign_in")}
        </Typography>
        &nbsp;
        <Typography component="span" variant="body2">
          {translate("user.message.verify_email.sign_in")}
        </Typography>
      </Alert>
    </Container>
  );
};

export default ResendEmailVerification;
