export const getDateFromTimeString = (currentPickupTime: string): Date => {
  let pickupHours = Number(currentPickupTime.substring(0, 2));
  const pickupMinutes = Number(currentPickupTime.substring(3, 5));
  if (currentPickupTime.includes("PM") && pickupHours !== 12) {
    pickupHours += 12;
  }
  const currentPickupDate = new Date(
    new Date().setHours(Number(pickupHours), Number(pickupMinutes)),
  );
  return currentPickupDate;
};

export const getPickupRange = (currentPickupTime: string): string => {
  const currentPickupDate = getDateFromTimeString(currentPickupTime);
  currentPickupDate.setHours(currentPickupDate.getHours() + 1);
  const pickupRange = currentPickupDate.toLocaleString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  });
  return `${currentPickupTime}-${pickupRange}`;
};

export const TIME_DISPLAY_FORMAT = "hh:mm a";
